import {
  getCurrentInstance,
  reactive,
  ref,
  toRefs
} from 'vue'
import {
  Icon
} from '@iconify/vue'
/* eslint-disable vue/no-setup-props-destructure */
/* eslint-disable vue/no-dupe-keys */
// eslint-disable-next-line import/no-extraneous-dependencies
import exclamationIcon from '@iconify-icons/bi/exclamation'
import moment from 'moment'

export default {
  name: 'TableComissionConfirmation',
  components: {
    Icon
  },
  props: {
    data: {
      default: () => []
    }
  },
  setup(props: any) {
    // const app = getCurrentInstance()
    // const {
    //   $statusPayment
    // } = app!.appContext.config.globalProperties
    const icons = ref({
      exclamationIcon
    })
    const {
      data
    } = toRefs(props)

    const isValidData = () => {
      const filterConfirmation = data.value.filter((fil: any) => fil.PayoutNominal !== fil.Konfirmasi)
      if (filterConfirmation.length > 0) {
        return false
      }
      return true
    }
    return {
      data,
      icons,
      moment,
      isValidData,
      // $statusPayment
    }
  }
}