import ListBlankCourier from '@/views/master/courier/components/ListBlankCourier.vue'
import TableComissionConfirmation from '@/views/master/courier/comission-confirmation/TableComissionConfirmation.vue'
import FilterDatePayout from '@/views/master/courier/comission-confirmation/components/FilterDatePayout.vue'
import {
  getCurrentInstance,
  onMounted,
  ref
} from 'vue'
import {
  comissionConfirmationUseCase
} from '@/domain/usecase'
import {
  useStore
} from 'vuex'
import moment from 'moment'
import { cloneDeep } from 'lodash'

export default {
  name: 'ComissionConfirmation',
  components: {
    ListBlankCourier,
    TableComissionConfirmation,
    FilterDatePayout
  },
  setup() {
    const app = getCurrentInstance()
    const {
      $toast,
      $confirm
    } = app!.appContext.config.globalProperties
    const dateOp = ref()
    const store = useStore()
    const dataSourceFilter = ref([])
    const filterDate = ref(null) as any
    const dataTable = ref([])
    const refTableComissionConfirmation = ref()
    const showDropDown = (event: any) => {
      dateOp.value.toggle(event)
    }
    const getFilterDate = () => {
      store.dispatch('showLoading')
      comissionConfirmationUseCase.getFilterDatePayout().then((response: any) => {
        dataSourceFilter.value = response.result
        store.dispatch('hideLoading')
      })
    }
    const fetchDataTable = () => {
      store.dispatch('showLoading')
      comissionConfirmationUseCase.getCustomPayout(filterDate.value).then((response: any) => {
        if (!response.error) {
          // console.log('whyy', response)
          dataTable.value = response.result.map((x: any) => {
            x.TotalDenda = Math.abs(x.TotalDenda)
            x.Konfirmasi = x.IsConfirmed ? x.Total : 0
            return x
          })
        } else {
          $toast.add({
            severity: 'error',
            detail: response.result.detail,
            group: 'bc',
            life: 3000
          })
        }
        store.dispatch('hideLoading')
      })
    }

    const processSubmit = () => {
      const tempData = cloneDeep(refTableComissionConfirmation.value.data)
      const filterDataIsConfirmed = tempData.filter((x: any) => x.PayoutNominal !== x.Konfirmasi).length
      console.log(filterDataIsConfirmed)
      if (filterDataIsConfirmed > 0) {
        $toast.add({
          severity: 'error',
          detail: 'Pastikan nilai dari kolom Konfirmasi sama dengan kolom Total',
          group: 'bc',
          life: 3000
        })
      } else {
        store.dispatch('showLoading')
        const filterDataIsNotConfirmed = tempData.filter((x: any) => !x.IsConfirmed)
        // eslint-disable-next-line arrow-body-style
        const mapTempData = filterDataIsNotConfirmed.map((x: any) => {
          return {
            Id: x.Id,
            IsConfirmed: x.PayoutNominal === x.Konfirmasi
          }
        })
        comissionConfirmationUseCase.submitConfirmedPayout(mapTempData).then((response: any) => {
          if (!response.error) {
            $toast.add({
              severity: 'success',
              detail: response.result.detail ?? response.result.Detail,
              group: 'bc',
              life: 3000
            })
            fetchDataTable()
            getFilterDate()
          } else {
            $toast.add({
              severity: 'error',
              detail: response.message,
              group: 'bc',
              life: 3000
            })
          }
          store.dispatch('hideLoading')
        })
      }
    }

    const saveList = () => {
      $confirm.require({
        header: 'Konfirmasi Agen',
        message: 'Pastikan data yang divalidasi sudah benar',
        icon: 'pi pi-exclamation-triangle',
        accept: async () => {
          processSubmit()
        }
      })
      // if (refTableComissionConfirmation.value.isValidData() === true) {
      // } else {
      //   $toast.add({
      //     severity: 'error',
      //     detail: 'Pastikan nominal kolom Konfirmasi sama dengan kolom Total',
      //     group: 'bc',
      //     life: 3000
      //   })
      // }
    }

    const submitDateFilter = (val: any) => {
      filterDate.value = val.CutOffId
      fetchDataTable()
      dateOp.value.hide()
    }

    onMounted(() => {
      // fetchDataTable()
      getFilterDate()
    })
    return {
      showDropDown,
      dateOp,
      dataSourceFilter,
      submitDateFilter,
      moment,
      filterDate,
      saveList,
      dataTable,
      fetchDataTable,
      refTableComissionConfirmation
    }
  }
}
